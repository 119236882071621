import { render, useState, useEffect } from '@wordpress/element';
import VideoThumbnail from 'react-video-thumbnail';
const $ = jQuery;

import './style.scss';
import Style from './Style';
import VideoGalleryFilter from './VideoGalleryFilter';
import { controlsHandler } from './Const/functions';

// Video Gallery
document.addEventListener('DOMContentLoaded', () => {
	const allVideoGalleries = document.querySelectorAll('.wp-block-vgb-video-gallery');
	allVideoGalleries.forEach(gallery => {
		const attributes = JSON.parse(gallery.dataset.attributes);

		render(<RenderVideoGallery attributes={attributes} clientId={attributes.cId} />, gallery);

		gallery?.removeAttribute('data-attributes');
	});
});

const RenderVideoGallery = ({ attributes, clientId }) => {
	const { videos } = attributes;

	const [itemWidth, setItemWidth] = useState('');

	useEffect(() => {
		// Initial fancybox
		$('.galleryItem').fancybox({
			baseClass: `vgbFancyBox vgbFancyBox-${clientId}`,
			buttons: [
				'slideShow',
				// 'thumbs',
				// 'zoom',
				// 'fullScreen',
				// 'share',
				'close'
			],
			loop: false,
			protect: true,
			afterShow: () => {
				// Initial Plyr after fancybox popup display
				new Plyr(`.vgbFancyBox-${clientId} .fancybox-video`, {
					controls: controlsHandler({
						'play-large': true,
						restart: false,
						rewind: true,
						play: true,
						'fast-forward': true,
						progress: true,
						'current-time': true,
						duration: false,
						mute: true,
						volume: true,
						pip: false,
						airplay: false,
						settings: true,
						download: false,
						fullscreen: true
					}),
					clickToPlay: false,
					loop: { active: false },
					muted: false,
					autoplay: false,
					resetOnEnd: false,
					hideControls: true,
				});
			}
		});
	}, []);

	return <>
		<Style attributes={attributes} clientId={clientId} itemWidth={itemWidth} />

		<div className='vgbVideoGallery'>
			<VideoGalleryFilter attributes={attributes} clientId={clientId} itemWidth={itemWidth} setItemWidth={setItemWidth} />

			<div id={`videoGallery-${clientId}`} className='videoGallery'>
				{videos?.map((item, index) => {
					const { video, poster, albs } = item;

					return <a
						key={index}
						className={`galleryItem ${albs?.map(c => lodash.camelCase(c)).join(' ')}`}
						data-fancybox={`fancybox-${clientId}`}
						href={video || poster}
					>
						{poster ? <figure className='galleryFigure'>
							<img src={poster} />
						</figure> : <VideoThumbnail width={600} videoUrl={video} snapshotAtTime={2} />}
					</a>;
				})}
			</div>
		</div>
	</>
}