import { useEffect, useRef } from '@wordpress/element';
const $ = jQuery;

const VideoGalleryFilter = ({ attributes, clientId, itemWidth, setItemWidth }) => {
	const { align, albums, videos, columns, columnGap, rowGap, padding, border, itemHeight } = attributes;

	const vgbColumn = useRef(null);

	useEffect(() => {
		setItemWidth(vgbColumn.current.clientWidth);
	}, [vgbColumn.current, align, columns, columnGap, rowGap, padding, border]);

	// Icotope
	useEffect(() => {
		const isoOptions = {
			filter: '*',
			itemSelector: '.galleryItem',
			masonry: {
				fitWidth: true, // When enabled, you can center the container with CSS.
				gutter: columnGap
			},
			stagger: 30,
			transitionDuration: '0.5s'
		}

		const vgbIso = $(`#videoGallery-${clientId}`).isotope(isoOptions);
		vgbIso.isotope('destroy');
		vgbIso.isotope(isoOptions);

		// Filter items on button click
		$(`#filter-${clientId}`).on('click', 'button', function () {
			$(`#filter-${clientId} .current`).removeClass('current');
			$(this).addClass('current');

			const filterValue = $(this).attr('data-filter');
			vgbIso.isotope({ ...isoOptions, filter: filterValue });
		});
	}, [align, videos?.length, columns, columnGap, rowGap, itemHeight, itemWidth, padding, border]);

	return <>
		<div id={`filter-${clientId}`} className='filter'>
			<button data-filter='*' className='current'>All Videos</button>
			{albums?.map(alb => <button key={lodash.camelCase(alb)} data-filter={`.${lodash.camelCase(alb)}`}>{alb}</button>)}
		</div>

		<div className={`vgbColumnSizer columns-${columns.desktop} columns-tablet-${columns.tablet} columns-mobile-${columns.mobile}`}>
			<div className='vgbColumn' ref={vgbColumn}></div>
		</div>
	</>
}
export default VideoGalleryFilter;