const Style = ({ attributes, clientId, itemWidth }) => {
	const { columnGap, rowGap, background, padding, border, filterBtnTypo, filterBtnColors, filterBtnHoverColors, itemHeight } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${filterBtnTypo?.googleFontLink ? `@import url(${filterBtnTypo?.googleFontLink});` : ''}

		#vgbVideoGallery-${clientId} .vgbVideoGallery{
			${background?.styles || 'background: #fff;'}
			padding: ${padding?.styles || '10px 10px'};
			${border?.styles || 'border-radius: 5px;'}
			box-shadow: 0px 25px 30px -20px rgba(0, 0, 0, 0.2);
		}
		#vgbVideoGallery-${clientId} .vgbVideoGallery .filter button{
			${filterBtnTypo?.styles || 'font-size: 14px;'}
			${filterBtnColors?.styles || 'color: #fff; background: #4527a4;'}
		}
		#vgbVideoGallery-${clientId} .vgbVideoGallery .filter button:hover, #vgbVideoGallery-${clientId} .vgbVideoGallery .filter button.current{
			${filterBtnHoverColors?.styles || 'color: #fff; background: #8344c5;'}
		}
		#vgbVideoGallery-${clientId} .vgbVideoGallery .vgbColumnSizer {
			grid-column-gap: ${columnGap}px;
		}
		#vgbVideoGallery-${clientId} .vgbVideoGallery .videoGallery .galleryItem{
			width: ${itemWidth}px;
			height: ${itemHeight};
			margin-bottom: ${rowGap}px;
		}
		`.replace(/\s+/g, ' ')
	}} />
}
export default Style;